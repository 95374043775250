import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import Button from "./ButtonRed";
import VerticalPixelImage from './VerticalPixelImage';
import pixelRectangleImage from '../images/pixel-rectangle.svg'
import whiteStripesImage from "../images/white-stripes-banner.svg"


export default function BlueBanner(props) {

  return (
    <div className='blue-banner px-xs-0 px-lg-5'>
      <Container className="px-sm-0">
        <Row className="align-items-sm-center align-items-lg-start">
          <Col className="ml-5 px-0 px-lg-0 pt-lg-5" xs={12} md={12} lg={{ span: 5, offset: 1 }}>
            <div className="d-flex justify-content-lg-end px-0 d-lg-block">
              <Row className="px-0 pt-lg-0 m-0 title-container w-100 px-md-3 ps-lg-0">
                <Col className="px-0 title-column m-0 pt-5 pt-lg-0" xs={10} lg={12}>
                  <p className="subtitle-banner text-center text-lg-start fw-bold">{props.bannerSubtitle}</p>
                  <h2 className="title-banner text-center text-lg-start fw-bold">{props.bannerTitle}</h2>
                </Col>
                  <Col className="pe-0 ps-2 m-0 d-flex justify-content-end" xs={2} lg={12}>
                    <div className="d-flex d-lg-none justify-content-end">
                      <VerticalPixelImage />
                    </div>
                  </Col>
              </Row>
            </div>
            <Col className="d-flex my-5 d-lg-none justify-content-center" xs={12}>
              <Image
                className="image-banner"
                src={props.urlImage}
                alt={props.alt}
                placeholder="none"
              />
            </Col>
            <p className="description-banner text-center text-lg-start px-4 px-lg-0 fw-light">{props.bannerDescription}</p>
            <div className="d-flex separator-blue-line-container justify-content-center justify-content-lg-start pe-0 my-4">
              <div className="separator-blue-line">
              </div>
            </div>
            <div className="pe-0 mb-5">
              <Button title="ÚNETE A SOWOS" href={`${process.env.GATSBY_APP_URL}/sign-up`} />
            </div>
          </Col>
          <Col className="d-none d-lg-flex justify-content-center pt-lg-5" lg={5}>
            <img
              className="image-banner"
              src={props.urlImage}
              alt={props.alt}
            />
          </Col>
          <Col className="vertical-image-container d-none d-lg-flex align-items-start" lg={1}>
            <VerticalPixelImage />
          </Col>
        </Row>
        <Row className="d-sm-flex justify-content-sm-between">
          <Col className="px-0 px-lg-3" xs={6} lg={{ span: 6 }}>
            <img
              className="pixel-rectangle"
              src={pixelRectangleImage}
              alt="Imagen de un rectángulo de color azul wealth."
            />
          </Col>
          <Col className="d-flex align-items-end justify-content-end justify-content-lg-center px-0" xs={6} lg={{ span: 5 }}>
            <img
              className="white-stripes"
              src={whiteStripesImage}
              alt="Imagen de un rectángulo en color blanco."
            />
          </Col>
        </Row>
      </Container>
    </div >
  );
}
